@import '../../../vars_site';

.accueil-site {
  background-image: url("../../../images/accueil/gradient-bg.png"), url("../../../images/accueil/grenoble.jpg");
  background-repeat: repeat, repeat;
  background-position: center;
  
  .logo-acte2i-accueil {
    width: 30%;
  }
  
  h1 {
    color: white;
    font-size: 1.5em;
  }
  
  .telephone-acte2i {
    color: white;
    text-shadow: 1px 1px $bleu-fonce;
    font-size: 1.5em;
    font-weight: 600;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

.apercu-qui-sommes-nous {
  margin-top: 70px;
  
  h2 {
    font-weight: 600;
    font-size: 2.2em;
    
    background-image: url('../../../images/left-blue-line-title.svg'), url('../../../images/right-blue-line-title.svg');
    background-repeat: no-repeat;
    background-position: center left -20px, center right -90px;
  }
  
  .wrapper-icones {
    display: flex;
    
    .wrapper-icone-texte {
      display: inline-block;
      margin: auto;
      
      .wrapper-icone {
        height: 75px;
      }
    }
  }
}

.metiers-acte2i {
  background-image: url('../../../images/index/white-triangle-left.svg'), url('../../../images/index/white-triangle-right.svg');
  background-repeat: no-repeat;
  background-position: top 40px left, bottom -150px right;
  background-size: 1000px, 710px;
  
  h2 {
    font-size: 3em;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 4rem;
  }
  
  a.lien-metier {
    position: relative;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.45);
    
    img.image-illustration-metier {
      width: 100%;
    }
    
    .wrapper-arrow-lien-metier {
      display: inline-block;
      padding: 20px 15px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    
    .cache-hover {
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      display: none;
    }
    
    &:hover {
      .wrapper-arrow-lien-metier.bg-orange {
        background-color: $bleu-clair;
      }
      
      .wrapper-arrow-lien-metier.bg-bleu-clair {
        background-color: $orange;
      }
      
      .cache-hover {
        display: block;
      }
    }
  }
}

.temoignages-clients {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  
  .container-temoignages-clients {
    background-color: white;
    margin-top: -135px;
    margin-bottom: -110px;
    padding-bottom: 110px;
    position: relative;
    
    h2 {
      color: $bleu-fonce;
      font-weight: bold;
    }
    
    .temoignage {
      border: 2px solid $bleu-clair;
      border-radius: 10px;
      padding: 40px 45px 15px;
      position: relative;
      
      .photo-temoin {
        position: absolute;
        top: -50px;
        left: 20px;
      }
      
      .texte-temoignage {
        color: #3D3D3D;
        min-height: 80px;
        display: flex;
        align-items: center;
      }
      
      .infos-temoin {
        p {
          color: #999;
          font-size: 0.9em;
        }
      }
    }
  }
}

.actualites {
  background-image: url('../../../images/index/white-triangle-left.svg'), url('../../../images/index/white-triangle-right.svg');
  background-repeat: no-repeat;
  background-position: top 100px left -500px, bottom -150px right -200px;
  background-size: 1000px, 710px;
  
  h2 {
    font-weight: bold;
  }
}

@media (max-width: 991px) {
  .accueil-site {
    .logo-acte2i-accueil {
      width: 50%;
    }
  }
  
  .metiers-acte2i {
    h2 {
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .apercu-qui-sommes-nous {
    h2 {
      background: none;
    }
  }
}

@media (max-width: 500px) {
  .metiers-acte2i {
    h2 {
      font-size: 3em;
    }
  }
}

@media (max-width: 380px) {
  .metiers-acte2i {
    h2 {
      font-size: 2em;
    }
  }
}

@media (min-width: 992px) {
  .metiers-acte2i {
    a.lien-metier {
      img.image-illustration-metier {
        max-width: 100%;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .apercu-qui-sommes-nous {
    h2 {
      background: none;
    }
  }
}
