@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
.titre-identification-diagnostics {
  background-color: #D2E9F0;
  padding: 25px 0;
  margin-top: -89px;
}
.titre-identification-diagnostics h2 {
  font-size: 2.5me;
  font-weight: 600;
  margin-bottom: 0;
}

.wrapper-formulaire-recherche-diagnostics {
  box-shadow: 0 10px 15px -9px rgba(0, 0, 0, 0.25) !important;
  background-color: white;
}
.wrapper-formulaire-recherche-diagnostics .errors-form-diagnostics {
  margin-bottom: 30px;
}
.wrapper-formulaire-recherche-diagnostics .errors-form-diagnostics ul {
  margin-bottom: 0;
  list-style: none;
  padding-left: 10px;
}
.wrapper-formulaire-recherche-diagnostics select {
  background-color: white;
  border: 2px solid #00A3E2;
  border-radius: 20px;
  padding: 8px 20px;
  width: 100%;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url(/build/images/chevron-down-s.266c63d7.png);
  background-repeat: no-repeat;
  background-position: center right 10px;
}
.wrapper-formulaire-recherche-diagnostics input {
  background-color: white;
  border: 2px solid #00A3E2;
  border-radius: 20px;
  padding: 8px 20px;
  width: 100%;
}
.wrapper-formulaire-recherche-diagnostics select:focus,
.wrapper-formulaire-recherche-diagnostics input:focus {
  box-shadow: none;
}
.wrapper-formulaire-recherche-diagnostics select.with-value,
.wrapper-formulaire-recherche-diagnostics input.with-value {
  border-color: #00c000;
}
.wrapper-formulaire-recherche-diagnostics button {
  color: #FA8A07;
  border: none;
  background-color: white;
  padding: 8px 25px;
  border-radius: 5px;
}
.wrapper-formulaire-recherche-diagnostics button:hover {
  background-color: #f4f4f4;
}
.wrapper-formulaire-recherche-diagnostics .webf-list .webf-list-item > a,
.wrapper-formulaire-recherche-diagnostics .webf-list .webf-list-item > label {
  height: inherit;
  line-height: inherit;
  font-size: inherit;
}

@media (max-width: 991px) {
  .recherche-diagnostics button {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .wrapper-formulaire-recherche-diagnostics select, .wrapper-formulaire-recherche-diagnostics input {
    width: 75%;
  }
}
.accueil-site {
  background-image: url(/build/images/gradient-bg.2e846928.png), url(/build/images/grenoble.2a2ff9af.jpg);
  background-repeat: repeat, repeat;
  background-position: center;
}
.accueil-site .logo-acte2i-accueil {
  width: 30%;
}
.accueil-site h1 {
  color: white;
  font-size: 1.5em;
}
.accueil-site .telephone-acte2i {
  color: white;
  text-shadow: 1px 1px #003D6B;
  font-size: 1.5em;
  font-weight: 600;
  text-decoration: none;
}
.accueil-site .telephone-acte2i:hover {
  text-decoration: underline;
}

.apercu-qui-sommes-nous {
  margin-top: 70px;
}
.apercu-qui-sommes-nous h2 {
  font-weight: 600;
  font-size: 2.2em;
  background-image: url(/build/images/left-blue-line-title.48594c39.svg), url(/build/images/right-blue-line-title.ba469b27.svg);
  background-repeat: no-repeat;
  background-position: center left -20px, center right -90px;
}
.apercu-qui-sommes-nous .wrapper-icones {
  display: flex;
}
.apercu-qui-sommes-nous .wrapper-icones .wrapper-icone-texte {
  display: inline-block;
  margin: auto;
}
.apercu-qui-sommes-nous .wrapper-icones .wrapper-icone-texte .wrapper-icone {
  height: 75px;
}

.metiers-acte2i {
  background-image: url(/build/images/white-triangle-left.cd97b5a3.svg), url(/build/images/white-triangle-right.2b60e456.svg);
  background-repeat: no-repeat;
  background-position: top 40px left, bottom -150px right;
  background-size: 1000px, 710px;
}
.metiers-acte2i h2 {
  font-size: 3em;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 4rem;
}
.metiers-acte2i a.lien-metier {
  position: relative;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.45);
}
.metiers-acte2i a.lien-metier img.image-illustration-metier {
  width: 100%;
}
.metiers-acte2i a.lien-metier .wrapper-arrow-lien-metier {
  display: inline-block;
  padding: 20px 15px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.metiers-acte2i a.lien-metier .cache-hover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  display: none;
}
.metiers-acte2i a.lien-metier:hover .wrapper-arrow-lien-metier.bg-orange {
  background-color: #00A3E2;
}
.metiers-acte2i a.lien-metier:hover .wrapper-arrow-lien-metier.bg-bleu-clair {
  background-color: #FA8A07;
}
.metiers-acte2i a.lien-metier:hover .cache-hover {
  display: block;
}

.temoignages-clients {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
}
.temoignages-clients .container-temoignages-clients {
  background-color: white;
  margin-top: -135px;
  margin-bottom: -110px;
  padding-bottom: 110px;
  position: relative;
}
.temoignages-clients .container-temoignages-clients h2 {
  color: #003D6B;
  font-weight: bold;
}
.temoignages-clients .container-temoignages-clients .temoignage {
  border: 2px solid #00A3E2;
  border-radius: 10px;
  padding: 40px 45px 15px;
  position: relative;
}
.temoignages-clients .container-temoignages-clients .temoignage .photo-temoin {
  position: absolute;
  top: -50px;
  left: 20px;
}
.temoignages-clients .container-temoignages-clients .temoignage .texte-temoignage {
  color: #3D3D3D;
  min-height: 80px;
  display: flex;
  align-items: center;
}
.temoignages-clients .container-temoignages-clients .temoignage .infos-temoin p {
  color: #999;
  font-size: 0.9em;
}

.actualites {
  background-image: url(/build/images/white-triangle-left.cd97b5a3.svg), url(/build/images/white-triangle-right.2b60e456.svg);
  background-repeat: no-repeat;
  background-position: top 100px left -500px, bottom -150px right -200px;
  background-size: 1000px, 710px;
}
.actualites h2 {
  font-weight: bold;
}

@media (max-width: 991px) {
  .accueil-site .logo-acte2i-accueil {
    width: 50%;
  }
  .metiers-acte2i h2 {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .apercu-qui-sommes-nous h2 {
    background: none;
  }
}
@media (max-width: 500px) {
  .metiers-acte2i h2 {
    font-size: 3em;
  }
}
@media (max-width: 380px) {
  .metiers-acte2i h2 {
    font-size: 2em;
  }
}
@media (min-width: 992px) {
  .metiers-acte2i a.lien-metier img.image-illustration-metier {
    max-width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .apercu-qui-sommes-nous h2 {
    background: none;
  }
}
