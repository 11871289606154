@import '../../vars_site';

.titre-identification-diagnostics {
  background-color: #D2E9F0;
  padding: 25px 0;
  margin-top: -89px;
  
  h2 {
    font-size: 2.5me;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.wrapper-formulaire-recherche-diagnostics {
  box-shadow: 0 10px 15px -9px rgba(0, 0, 0, 0.25) !important;
  background-color: white;
  
  .errors-form-diagnostics {
    margin-bottom: 30px;
    
    ul {
      margin-bottom: 0;
      list-style: none;
      padding-left: 10px;
    }
  }
  
  select {
    background-color: white;
    border: 2px solid $bleu-clair;
    border-radius: 20px;
    padding: 8px 20px;
    width: 100%;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-image: url('../../images/chevron-down-s.png');
    background-repeat: no-repeat;
    background-position: center right 10px;
  }
  
  input {
    background-color: white;
    border: 2px solid $bleu-clair;
    border-radius: 20px;
    padding: 8px 20px;
    width: 100%;
  }
  
  select,
  input {
    &:focus {
      box-shadow: none;
    }
    
    &.with-value {
      border-color: #00c000;
    }
  }
  
  button {
    color: $orange;
    border: none;
    background-color: white;
    padding: 8px 25px;
    border-radius: 5px;
    
    &:hover {
      background-color: #f4f4f4;
    }
  }
  
  .webf-list .webf-list-item > a,
  .webf-list .webf-list-item > label {
    height: inherit;
    line-height: inherit;
    font-size: inherit;
  }
}

@media (max-width: 991px) {
  .recherche-diagnostics {
    button {
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .wrapper-formulaire-recherche-diagnostics {
    select, input {
      width: 75%;
    }
  }
}
